import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as serviceWorker from './serviceWorker';

import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from "react-redux";
import React from 'react';
import ReactDOM from 'react-dom';
import {configureStore} from './store';
import {isLoggedIn} from './services/AuthorizeAdmin';

//  ReactDOM.render(<App />, document.getElementById('root'));

const renderApp = preloadedState => {
    const store = configureStore(preloadedState);
    window.state = store.getState;
  
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>,
      document.getElementById("root")
    );
  };
 
  
  (async () => renderApp(await isLoggedIn()))();
  
  serviceWorker.unregister();
